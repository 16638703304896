import React from "react"

import Layout from "../../components/Layout"
import Grid from "../../components/Grid"
import { Link } from "gatsby"
import css from "../setup.module.scss"
import fontType from "../../helpers/fontType.module.scss"

import TRACKINGCODE_EMAIL from "../../assets/images/setup/setup-trackcode-email.png"
import SETUP_VOLUSION_MENU from "../../assets/images/setup/setup-volusion-menu.png"
import SETUP_VOLUSION_130 from "../../assets/images/setup/setup-volusion-130.png"
import SETUP_VOLUSION_PASTE from "../../assets/images/setup/setup-volusion-paste.png"
import SETUP_VOLUSION_COMPANY from "../../assets/images/setup/setup-volusion-company.png"
import SETUP_VOLUSION_BCC from "../../assets/images/setup/setup-volusion-bcc.png"

const metadata = {
  title: "Connect Volusion to ReferralCandy",
}

const VolusionSetup = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container} centeredClassName={css.centered}>
        <h1 className={css.center}>Set Up ReferralCandy for Volusion</h1>
        <p className={fontType.b1}>
          Step 1: Setting up your ReferralCandy Account
        </p>
        <ol type="1">
          <li>
            Head over to the{" "}
            <a href="https://my.referralcandy.com/signup#volusion">
              sign up page
            </a>{" "}
            to register for a new ReferralCandy account.
          </li>
          <li>
            Go through the account setup wizard. At the integration step, note
            the tracking code and email address for your referral program. You
            will need them for the next two parts.
          </li>
          <br />
          <br />
          <img src={TRACKINGCODE_EMAIL} alt="Tracking Code Email" />
          <li>Complete the rest of the setup wizard.</li>
        </ol>
        <p className={fontType.b1}>
          Step 2: Adding the tracking code to your Volusion store
        </p>
        <ol type="1">
          <li>Log into your Volusion Control Panel.</li>
          <li>
            In the Control Panel, click "Design" in the top navigation bar and
            click "Site Content" in the dropdown box that appears.
          </li>
          <br />
          <br />
          <img src={SETUP_VOLUSION_MENU} alt="Setup Volusion menu" />
          <li>
            In the "Update Article" panel, scroll down to the "130
            OrderFinished.asp" row and click the "130" link.
          </li>
          <br />
          <br />
          <img src={SETUP_VOLUSION_130} alt="Setup Volusion 130" />
          <li>
            In the "Article Management | 130" panel, copy and paste the
            ReferralCandy tracking code from Step 2 into the "Article Body"
            input box. Note: Before you paste the tracking code into the input
            box, make sure that the "&lt;/&gt;" button is depressed (it should
            look like how it looks in the image below).
          </li>
          <li>
            Click the "Save" button that pops up at the bottom to save the
            changes.
          </li>
          <br />
          <br />
          <img src={SETUP_VOLUSION_PASTE} alt="Setup Volusion paste" />
        </ol>
        <p className={fontType.b1}>
          Step 3: Sending invoice emails to ReferralCandy
        </p>
        <ol type="1">
          <li>
            In your store’s Control Panel, click "Settings" in the top
            navigation bar and click "Company" in the dropdown box that appears.
          </li>
          <br />
          <br />
          <img src={SETUP_VOLUSION_COMPANY} alt="Setup Volusion company" />
          <li>
            In the Company Information panel, click the "More" link in the
            "Email" row to open a blue panel below.
          </li>
          <li>
            Copy and paste the ReferralCandy email address from Step 2 into the
            input box in the "CC Orders To" row.
          </li>
          <br />
          <br />
          <img src={SETUP_VOLUSION_BCC} alt="Setup Volusion BCC" />
          <li>Click the "Save" button below to save the changes.</li>
        </ol>
        <h3>
          Using a different platform? <Link to="/setup">View all guides</Link>
        </h3>
      </Grid>
    </Layout>
  )
}

export default VolusionSetup
